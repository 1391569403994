import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Fancybox from "../components/fancybox"
import Carousel from 'react-multi-carousel';
import NewsletterGen from "../components/newslettergen"
import "../styles/new.min.css"



export default function WhoTemplate() {
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 1
  }
};
  return (
    <Layout breadcrumbs={["Massachusetts Legislators Report"]}>
	<Carousel 
  swipeable={false}
  infinite={true}
  autoPlay={false}
  arrows={false}
  autoPlaySpeed={3000}
  customTransition="all .5"
  transitionDuration={500}  
  responsive={responsive}
  >
	<div>
    <div className="carousel-container">
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1729527079/events/BBA_Intelligence_Series_2025_-_Social_Media_adnyl4.png" className="group-3" />
    </div>
    </div>
	</Carousel>
    <div className="container-noflex-resources-events">
	  <div className="event-seperate">
      <h2><b>Revolutionary Health: Powering the Next Frontier with Blockchain and AI</b></h2>
      <p><b>Date:</b> March 2025</p>
      <p><b>Location:</b> Suffolk University, Boston, MA</p>
	  <button className="btn-new">Register for Event</button>
      </div>
	  <h3>About the Event</h3>
      <p>The healthcare industry is going through a major transformation. With skyrocketing costs and isolated business models, smart companies are taking advantage of new technologies to change the healthcare model. Emerging technologies like Blockchain and AI are unlocking new possibilities for Biotech, Pharma, Life Sciences and Healthcare by creating more transparent, secure and predictable systems. These technologies can optimize data management and integration, speed up drug discovery and development, and streamline supply chain logistics, which ultimately improves patient outcomes.</p><p>Boston, as a leader in global healthcare innovation, will host this pivotal event to bring together thought leaders from across the industry. You’ll learn how leading-edge innovators are using blockchain and AI to power the next frontier in healthcare, leading the industry forward.</p>
      <div className="event-seperate">
	  <p><i>Target Audience:</i></p>
      <ul><li>Biotech, Pharma, Life Science, Healthcare, Enterprise Tech</li><li>Blockchain and AI Startups, Tech Platforms</li><li>Regulators, Payors, Service Providers</li><li>Individual Clinicians, Consumer and Patient Advocacy Groups</li><li>Academics, Researchers, Health Systems, Investors</li><li>Health Policy Leaders, Industry Standards Experts</li></ul>
      </div>
	  <h3>Sponsors</h3>
      <p>Download the full <a href="/_2025 BBA Intelligence Series Sell Sheet V1 (1) (2).pdf">Sponsor Sell Sheet PDF</a> for more information on how to become a sponsor and the benefits involved.</p>
      <h3>Speakers</h3> 
      <ul><li>TBD</li></ul>
      <h3>Agenda</h3>
      <p>This full-day, in-person event will feature a series of panels, presentations, and networking opportunities. Participants can expect discussions covering emerging technologies in healthcare, as well as the latest solutions in bio/pharmaceutical discovery and development, followed by cocktails and hors d'oeuvres to close the day.</p>
      <ul><li><b>1:00 PM - 1:30 PM</b> "AI and Blockchain: Transforming Healthcare and Life Sciences"<br />Keynote presentation highlighting the transformative role of AI and blockchain in healthcare, setting the stage for the day’s discussions.</li><li><b>1:30 PM - 2:15 PM</b> "Blockchain in Healthcare: A Double-Edged Sword"<br />Is blockchain real? Experts discuss blockchain's potential benefits (data privacy, trust, and transparency) and challenges (scalability, regulation, and integration).</li><li><b>2:15 PM - 3:15 PM</b> "Unlocking Practical Applications of AI and Blockchain"<br />Real-world examples, such as Denmark’s predictive Health, Curdis, Hair DAO etc. What we can learn from early adopters and innovators, and current use cases.</li><li><b>3:15 PM - 4:00 PM</b> "From Average to Optimal: Future Directions in AI and Blockchain"<br />Visionaries discuss individual optimization, privacy-preserving data infrastructures, and the societal impact of incentivized health data sharing.</li><li><b>4:00 PM - 4:30 PM</b> “Panel: Institutional Investor Perspective”<br />What are the leading firms planning for, and when do they expect to see the payoff?</li><li><b>4:30 PM - 5:00 PM</b> "Bridging the Gap: Building Trust and Innovation Together"<br />Facilitators summarize insights, outline actionable steps, and invite audience to help shape the future of AI, blockchain, and healthcare going forward.</li><li><b>5:00 PM - 6:30 PM</b> Networking Reception</li></ul>
	  <h3>Organizing Committee</h3>
	<ul>
		<li><a href="https://www.linkedin.com/in/kjmooney/" target="_blank">Ken Mooney</a>, Suffolk University</li>
		<li><a href="https://www.linkedin.com/in/mona-al-amin-7834aa22/" target="_blank">Mona Al-Amin</a>, Suffolk University</li>
		<li><a href="https://www.linkedin.com/in/sharilee/" target="_blank">Shari Worthington, PhD</a>, Suffolk University</li>
		<li><a href="https://www.linkedin.com/in/brigittepiniewski/" target="_blank">Brigitte Piniewski, MD</a>, BlockHealth</li>
		<li><a href="https://www.linkedin.com/in/heatherflannery/" target="_blank">Heather Flannery</a>, AI MINDSystems Foundation</li>
		<li><a href="https://www.linkedin.com/in/sean-manionphd/" target="_blank">Sean Mannion, PhD</a>, AI MINDSystems Foundation</li>
		<li><a href="https://www.linkedin.com/in/dougmehne/" target="_blank">Doug Mehne</a>, Protego Trust, Boston Blockchain Association</li>
	 </ul>	
	  <button className="btn-new">Register for Event</button>	 
   </div>  
    <div className="container-noflex-events">
        <div className=" who-we-are-new-volunteer-cta-new">
          <div className=" who-we-are-new-volunteer-content-new">
            <h2>Join Our Newsletter</h2>
            <p>To get notified about all the blockchain events in Boston (BBA and beyond), sign up for our weekly newsletter.</p>
          </div>
          <div className="who-we-are-new-volunteer-img-hand">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701526498/events/1000_F_361449961_i1QO8dNVVvwzGHdEFI1j7f67GbBWHZXq_1_xequcv.png" />
          </div>         
         </div>  
         <div className="newsletter-container-events">
      <h3 className="subscribe-to-the-newsletter">Subscribe to the Newsletter</h3>
	  <div className="subscribe-to-the-boston-blockchain-association-newsletter-and-get-notified-on-the-latest-blockchain-events-job-openings-opportunities-and-more" >Subscribe to the Boston Blockchain Association newsletter and get notified on the latest blockchain events, job openings, opportunities, and more. </div>
      <div className="newsletter-form-container">
	  <NewsletterGen />
	  </div>
    </div>         
    </div> 
      </Layout>
  )
}